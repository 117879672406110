import React,{useState,useEffect,useContext} from 'react';
import { get,Config } from '../../../helpers/storage_helper';
// import Flag from "react-flags";
import { listAccountProvider } from '../../../actions/account_provider_actions';
import { getManagementDashboardReport } from '../../../actions/report_actions';
import {RoundOffSplitter, DateFormatter, format_date_tbl} from '../../../helpers/common_helper';
import { Link } from 'react-router-dom';
import moment from "moment";
import {IoIosGlobe} from "react-icons/io";
import {listMarkets} from '../../../actions/common_actions';
import LenderCode from '../component/LenderCode';
import CustomFlag from '../../common/component/CustomFlag';

export default function ManagementDashboard () {
    const [responseData, setresponseData] = useState([])       
    const [data, setdata] = useState([])       
    const [filteredCountrydata, setfilteredCountrydata] = useState([]) 
    const [cursorPointerStatus,setcursorPointerStatus] = useState(true)
    const [switch_indicate,set_switch_indicate]=useState(false) 
    const [switch_data,setSwitchDataState]=useState(false)   
    const [country, setcountry] = useState({
        country:'Global',
        country_code:'*'
    })
    const [currentCountryactiveCurrency, setcurrentCountryactiveCurrency] = useState({
        active_currency:''
    })
    const [activeCurrency, setactiveCurrency] = useState({
        currency_change:false,
        active_currency:'',
        active_currency_val:''
    })
    const [marketList,setmarketList]=useState([])
    const [current_country,setcurrent_country] = useState(null)
    const [currencyDetails,setcurrencyDetails] = useState({})    
    const [accPrvdr,setaccPrvdr] = useState(null)
    const [accPurpose,setaccPurpose] = useState('Float Advance')
    const [allAccPrvdr,setallAccPrvdr] = useState([]) 
    const[lender,setLender] = useState(null)
    const[loader,setloader] = useState(false)

    const acc_purpose = ["Float Advance","Terminal Financing"]

    const switchCountries = Config("switch_supported_countries");

    useEffect(() => {
        setcountry({
            ["country"]: 'Global',
            ["country_code"]: '*'
        })
        setactiveCurrency({
            ["currency_change"]: false,
            ["active_currency"]: 'USD',
            ["active_currency_val"]: ''
        })
    },[])

    useEffect(() => {
        const marketCodes = marketList.map(country => country.country_code);
        if (switchCountries.includes(country.country_code) || 
            (switchCountries.some(countryCode => marketCodes.includes(countryCode)) && country.country_code === '*')) {
          setSwitchDataState(true);
          set_switch_indicate(true)
        } else {
          setSwitchDataState(false);
          set_switch_indicate(false)
        }
      }, [country, switchCountries, marketList]); 

        useEffect(() => {
      const req = {"report_type":"management_dashboard_live","time_zone":get('market').time_zone}
      setloader(true)
      getManagementDashboardReport(req)  
      .then((response)=>{
        
          if(response.status === "success"){
            setresponseData(response.data)
            setdata(response.data.records)
            const currency_details = response.data.currency_data
            setcurrencyDetails(currency_details)
            setcurrentCountryactiveCurrency({
                ["active_currency"] : country.country_code !== '*' ? currency_details[get('market').country_code].currency_code : 'USD'
            })
            const country_filtered_data = response.data.records.filter(e=>e.country_code === country.country_code)
            const acc_prvdr_filtered_data = country_filtered_data  //country_filtered_data.filter(e=>e.acc_prvdr_code === null)
            setfilteredCountrydata(acc_prvdr_filtered_data)
            setloader(false)

          }
      })

      if(get('market_list')){
        setmarketList(get('market_list'))


        }else{
            listMarkets().then((response)=>{
                if(!response){return}
                if(response){
                setmarketList(response.data)
                get_country_name(response.data)
                }
            }) 
        }
      if(country.country_code != '*'){
      var request = {country_code: country.country_code, time_zone: get('market').time_zone, status: ""};
      listAccountProvider({...request, status: 'enabled', biz_account: true})
		.then((response)=>{
			if(!response){return };
			if(response.status === "success"){
                let acc_prvdrs = []
                response.data.list.map(each=>
                    acc_prvdrs.push(each.acc_prvdr_code)
                )
                setallAccPrvdr(acc_prvdrs)                   
			}
		});}
    }, [setcountry, setdata,listAccountProvider])


    const countrySelecthandler = (countryCode,countryName) => {  

        setcursorPointerStatus(true)       
        setaccPrvdr(null)
        if(countryCode!="*"){
        setactiveCurrency({
            ['currency_change'] : countryCode === "UGA"  || countryCode === "RWA" ? false : true ,
            ["active_currency"] : currencyDetails[countryCode].currency_code,
            ["active_currency_val"] : ''
        })
        set_switch_indicate(false)   
		var req = {country_code: countryCode, time_zone: countryCode === "UGA" ? "EAT" : "CAT", status: ""};		
		listAccountProvider({...req, status: 'enabled', biz_account: true})
		.then((response)=>{
			if(!response){return };
			if(response.status === "success"){
                let acc_prvdrs = []
                response.data.list.map(each=>
                    acc_prvdrs.push(each.acc_prvdr_code)
                )
                setallAccPrvdr(acc_prvdrs)                   
			}
		}); }
        else{
            setactiveCurrency({
                ['currency_change'] : false ,
                ["active_currency"] : 'USD',
                ["active_currency_val"] : ''
            })
        }
        setcountry({
            ["country"] : countryName,
            ["country_code"] : countryCode
        })        
        const currentCountryactiveCurrency = countryCode !== '*' ? currencyDetails[countryCode].currency_code : 'USD'
        setcurrentCountryactiveCurrency({
            ["active_currency"] : currentCountryactiveCurrency
        })  
    
        const country_filtered_data = data.filter(e=>e.country_code === countryCode)     
        const acc_prvdr_filtered_data = countryCode !== '*' ? country_filtered_data.filter(e=>e.acc_prvdr_code === null && e.lender_code == null && e.sub_lender_code == null) : country_filtered_data
        setfilteredCountrydata(acc_prvdr_filtered_data)                             
    }  
    
    const handleLenderCode = (res) =>{
        const lender_code = res.value;
        const countryCode = country.country_code;
        let filtered_data = [];
        setLender(lender_code)
        const country_filtered_data = data.filter(e=>e.country_code === countryCode)     
        if(lender_code !== null && countryCode !== '*') {
            filtered_data = country_filtered_data.filter(e=>e.acc_prvdr_code === null &&  e.sub_lender_code === lender_code) 
        }else{
            filtered_data = country_filtered_data.filter(e=>e.acc_prvdr_code === null  && e.sub_lender_code == null)
        }  
        if(filtered_data.length > 0){
            setfilteredCountrydata(filtered_data) 
        }else{
            alert('No data Found')
        } 
      }

    const get_country_name = (data) => {
        const current_country = data.filter((item,idx)=>{ return item.country_code == country.country_code})
         setcurrent_country(current_country)
    }

    const prvdrSelectHandler =(accPrvdr) => {
        setcursorPointerStatus(false)
        setaccPrvdr(accPrvdr)
        const country_filtered_data = data.filter(e=>e.country_code === country.country_code)
        const acc_prvdr_filtered_data = country_filtered_data.filter(e=>e.acc_prvdr_code === accPrvdr)
        setfilteredCountrydata(acc_prvdr_filtered_data)        
    }

    const accPurposeClickHandler = (accPur) => {
        setaccPurpose(accPur)
    }

    const setCurrencyHandler = (curren) => {
        if(curren === "USD" || curren === "EUR"){            
            setactiveCurrency({
                ["currency_change"] : true,
                ["active_currency"] : curren,
                ["active_currency_val"] : currencyDetails[country.country_code].forex_rates[curren]
            })
        }
        else if (curren === "UGX" || curren === "RWF") {
            setactiveCurrency({
                ["currency_change"] : false,
                ["active_currency"] : curren,
                ["active_currency_val"] : ''
            })
        }
    }
    
    if(filteredCountrydata.length !==0 && !loader) {
        return (
            <div className='containerTopmargin max-width no-padding' id='convertPDF'>   
                <div style={{backgroundColor:'#1a2035'}} className='manage_dashContainer'>   
                    <div className='row no-margin align-items-center'>
                        <div className='col-md-8 no-padding'>  
                           {responseData &&  <h2 className='text-white m-0'> Start of Business - { moment(responseData.business_start_date.report_date).format('DD MMMM YYYY') }</h2>}           
                            <DateFormatter val={filteredCountrydata[0].report_date} />  
                        </div>  
                        <div className='col-md-4 no-padding d-flex justify-content-end'>                                         
                            {window.location.pathname === '/management-dashboard/print' ?
                                <div>
                                    <button className='btn-std' style={{borderRadius:5,border:'none',backgroundColor:'#fff'}} onClick={()=>window.print()}>Export to PDF</button>
                                    <Link to={"/management-dashboard"} className='btn-std' style={{marginLeft:15,borderRadius:5,border:'none',backgroundColor:'#fff'}}>Back</Link>
                                </div>
                                 :
                                <Link className='btn-std' style={{marginLeft:15,borderRadius:5,border:'none',backgroundColor:'#fff'}} to={"/management-dashboard/print"}>Export To PDF</Link>                                                                    
                            }                            
                        </div> 
                    </div>                                                        
                    <div className='row no-margin'>                        
                        <div className='col-md-3 no-padding'>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <label className='no-margin'>Market</label>    
                                </div>                                                                           
                                <div className="dropdown country_dropDownbg" style={{marginLeft:30}}>
                                    <div className="dropdown-toggle d-flex align-items-center dropdown_trig justify-content-between" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        {country.country_code != "*" ? 
                                            <CustomFlag country_code={country.country_code} height={30} width={30} alt={country.country_code}/>
                                            :
                                            <IoIosGlobe size={20} style={{margin: '4px 0px'}}/>
                                        }
                                      <span >{country.country ? country.country : ((current_country !=null) ? current_country[0].country :'')}</span>
                                        <span><i className="fa-solid fa-chevron-down"></i></span>
                                    </div>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{width:160,zIndex:200}}>
                                        <li className="country_selector" onClick={()=>countrySelecthandler("*","Global")}>
                                            <IoIosGlobe size={20} style={{margin:'4px 0px'}}/>
                                            <span style={{paddingLeft:15}}>Global</span>
                                        </li>
                                        { marketList && marketList.map((each,idx)=>
                                            <li key={idx} className="country_selector" onClick={()=>countrySelecthandler(each.country_code,each.country)}>                                    
                                                {/* <Flag id="market" name={each.country_code} format="png" pngSize={48} shiny={false} alt={each.country_code} basePath="/img/flags"/> */}
                                                <CustomFlag country_code={each.country_code} height={30} width={30} alt={each.country_code}/>
                                                <span style={{paddingLeft:5}}>{each.country}</span>
                                            </li>
                                        )}

                                    </ul>                        
                                </div>
                                {country.country_code!='*' && get('role_codes')!=="investor" &&
                                <div className='ml-4' style={{position:'relative',zIndex:200}}>
                                    <LenderCode country_code={country.country_code} theme={'country_dropDownbg'} onChange = {(res)=>handleLenderCode(res) }/>
                                </div>}
                            </div>
                        </div>
                        {country.country_code !== '*' &&
                            <>
                                <div className='col-md-7 no-padding d-flex align-items-center justify-content-end'>
                                    <p className='no-margin text-white'
                                       style={{fontWeight: 600}}>{activeCurrency.active_currency_val === "" ? "" : `1 ${activeCurrency.active_currency} =`}&nbsp;</p>
                                    <p className='no-margin text-white'
                                       style={{fontWeight: 600}}>{activeCurrency.active_currency_val === "" ? "" : Number(1 / activeCurrency.active_currency_val).toFixed(0)}&nbsp;{activeCurrency.active_currency_val === "" ? "" : currencyDetails[country.country_code].currency_code}</p>
                                </div>
                                <div className='col-md-2 no-padding'>
                                    <ul className='manDash_currencySelector no-padding no-margin justify-content-end align-items-center'>
                                        <li className='border_left'
                                            style={{backgroundColor: activeCurrency.active_currency === "USD" ? 'lightgray' : ''}}
                                            onClick={() => setCurrencyHandler('USD')}>
                                            <p className='no-margin'>USD</p>
                                        </li>
                                        <li style={{backgroundColor: activeCurrency.active_currency === "EUR" ? 'lightgray' : ''}}
                                            onClick={() => setCurrencyHandler('EUR')}>
                                            <p className='no-margin'>EUR</p>
                                        </li>
                                        <li style={{backgroundColor: activeCurrency.active_currency === currentCountryactiveCurrency.active_currency ? 'lightgray' : ''}}
                                            className='border_right'
                                            onClick={() => setCurrencyHandler(currentCountryactiveCurrency.active_currency)}>
                                            <p className='no-margin'>{currentCountryactiveCurrency.active_currency}</p>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        }
                    </div>

                        <div className='row no-margin justify-content-between'
                             style={{paddingBottom: 15, paddingTop: 15}}>
                            {country.country_code !== "*" && lender == null &&
                            <div className='col-md-8 no-padding'>
                                <div className='d-flex align-items-center'>
                                    <label className='no-margin' style={{paddingRight: 15}}>Provider</label>
                                    <button className={`filterBtns allBtn_size ${accPrvdr === null ? "" : "opac_50"}`}
                                            style={{
                                                height: 30,
                                                width: 60,
                                                cursor: cursorPointerStatus ? "pointer" : 'default',
                                                marginRight: 15
                                            }} key={0} onClick={() => prvdrSelectHandler(null)}>ALL
                                    </button>
                                    {
                                            allAccPrvdr.map((each, idx) =>
                                                <div style={{
                                                    height: 30,
                                                    width: 60,
                                                    cursor: cursorPointerStatus ? "pointer" : 'default',
                                                    marginRight: 15
                                                }} className={accPrvdr === null || accPrvdr === each ? "" : "opac_50"}
                                                     key={idx}
                                                     onClick={() => prvdrSelectHandler(each)}>
                                                    <img className="img-fluid" src={`/img/${each}_logo_color.png`}
                                                         alt={each}/>
                                                </div>
                                            )
                                    }
                                </div>
                            </div>}
                            <div className='col-md-4 no-padding d-flex justify-content-around align-items-center'>
                                {/* <div>
                                <label className='no-margin'>Loan Purpose</label>    
                            </div>                                                                           
                            <div className="dropdown country_dropDownbg">
                                <div className="dropdown-toggle d-flex align-items-center justify-content-between dropdown_trig" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">                            
                                    <span>{accPurpose}</span>
                                    <span><i className="fa-solid fa-chevron-down"></i></span>
                                </div>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{width:160}}>                                                                         
                                    {acc_purpose.map((each,idx)=>                                    
                                        <li className="country_selector" key={idx} onClick={() => accPurposeClickHandler(each)}>                                                                         
                                            <span style={{paddingLeft:5}}>{each}</span>
                                        </li> 
                                    )}                                                              
                                </ul>
                            </div> */}
                            </div>
                        </div>
                </div>                                 
                <div className='manDash_main'>
                    <div className='manDash_bgAbs'></div>
                    <div>
                        <div className='manDash_contentBox'>                        
                            <div className='manDash_content'>
                                <p className='no-margin manDash_header'>Business Volume</p>
                                <div className='row no-margin'>
                                    <div className='col-md-6'>
                                        <div className='manDash_roundBox'>
                                            <p className='no-margin text-center'>Loans Disbursed</p>                                            
                                            <p className=' no-margin text-center' style={{fontSize:'14px'}}><b>{filteredCountrydata[0].tot_disb_fas}</b></p>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='manDash_roundBox'>
                                            <p className='no-margin text-center'>Amount Disbursed</p>                                            
                                            <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].tot_disb_amt :  (filteredCountrydata[0].tot_disb_amt*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={true} position={"top"}/>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='manDash_roundBox'>
                                            <p className='no-margin text-center'>Average Loan Size</p>                                            
                                            <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].avg_fa_size :  (filteredCountrydata[0].avg_fa_size*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={true} position={"top"}/>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='manDash_roundBox'>
                                            <p className='no-margin text-center'>Average Loan Duration</p>
                                            <RoundOffSplitter val={filteredCountrydata[0].avg_fa_duration} unit={"Days"} align_center={true} position={"top"}/>                                            
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='manDash_roundBox'>
                                            <p className='no-margin text-center'>FA Gross Transaction Value</p>                                            
                                            <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].gross_txn_value :  (filteredCountrydata[0].gross_txn_value*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={true} position={"top"}/>
                                        </div>
                                    </div>
                                    { switch_data &&
                                    <div className='col-md-6'>
                                        <div className='manDash_roundBox' style={{padding:"12px"}}>
                                            <p className='no-margin text-center'>Switch Gross Transaction Value{ switch_indicate && (<span style={{ fontSize:'10px', color: 'rgb(0, 0, 0)' }}> (Uganda)</span>)}</p>                                            
                                            <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].sw_gross_txn_val :  (filteredCountrydata[0].sw_gross_txn_val*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={true} position={"top"}/>
                                        </div>
                                    </div>
                                    }
                                    <div className='col-md-6'>
                                        <div className='manDash_roundBox' style={{position:'relative'}}>
                                            <p className='no-margin text-center'>FA Revenue</p>
                                            <div style={{position:'absolute',top:5,right:5}}>
                                                {/* <RoundOffSplitter val={"This includes the penalty"} unit={""} align_center={true} position={"top"} notify={true}/>     */}
                                            </div>                                          
                                            <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].revenue :  (filteredCountrydata[0].revenue*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={true} position={"top"}/>
                                        </div>
                                    </div>  
                                    { switch_data &&
                                    <div className='col-md-6'>
                                        <div className='manDash_roundBox' style={{position:'relative'}}>
                                            <p className='no-margin text-center'>Switch Revenue {switch_indicate && (<span style={{ fontSize:'10px', color: 'rgb(0, 0, 0)' }}> (Uganda)</span> )}</p>
                                            <div style={{position:'absolute',top:5,right:5}}>
                                                {/* <RoundOffSplitter val={"This includes the penalty"} unit={""} align_center={true} position={"top"} notify={true}/>     */}
                                            </div>                                          
                                            <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].sw_revenue :  (filteredCountrydata[0].sw_revenue*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={true} position={"top"}/>
                                        </div>
                                    </div>   
                                    }                           
                                </div>
                            </div>   
                           
                            <div className='manDash_content white_bg'>
                                <p className='no-margin manDash_header'>Customer Metrics</p>
                                <div className='row no-margin align-items-center d-flex  p-3' style={{paddingBottom:15}}>
                                    <div className='col-md-12'>
                                        <div className='manDash_roundBox'  style={{position:"relative"}}>
                                            <div className='d-flex justify-content-center'>
                                                <p className='no-margin text-center'>Registered Customers</p>
                                                <p className='no-margin text-center' style={{fontWeight:600,paddingLeft:'2%'}}>{filteredCountrydata[0].reg_count}</p>
                                            </div>
                                            <div className='row no-margin' style={{paddingTop:'2%'}}>
                                                <div className='boderSeprator col-md-6'>
                                                    <p className='no-margin text-center'>FA Enabled Customers</p>
                                                    <div className='d-flex justify-content-center'>
                                                        <p className='no-margin' style={{fontWeight:600}}>{filteredCountrydata[0].enable_count}</p>
                                                        <p className='no-margin' style={{fontWeight:600,paddingLeft:'2%'}}>({!isNaN((filteredCountrydata[0].enable_count/(filteredCountrydata[0].reg_count/100)).toFixed(1)) ? (filteredCountrydata[0].enable_count/(filteredCountrydata[0].reg_count/100)).toFixed(1) : 0}%)</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='no-margin text-center'>Disabled Customers</p>
                                                    <div className='d-flex justify-content-center'>
                                                        <p className='  no-margin' style={{fontWeight:600}}>{(filteredCountrydata[0].reg_count-filteredCountrydata[0].enable_count)}</p>
                                                        <p className='no-margin ' style={{fontWeight:600,paddingLeft:'2%'}}>({!isNaN(((filteredCountrydata[0].reg_count-filteredCountrydata[0].enable_count)/(filteredCountrydata[0].reg_count/100)).toFixed(1)) ? ((filteredCountrydata[0].reg_count-filteredCountrydata[0].enable_count)/(filteredCountrydata[0].reg_count/100)).toFixed(1) : 0}%)</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>  
                                            {/* <div style={{position:'absolute',top:5,right:5}}>
                                                <RoundOffSplitter val={"Customers whose profiles are enabled on the system, meaning they can apply for loans (out of registered customers)"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                            </div>                                        */}
                                        </div>
                                    </div>

                                    <div className='col-md-12'>                                    
                                        <div className='manDash_roundBox' style={{position:"relative"}}>    
                                        <div className='row no-margin' style={{paddingTop:'2%',paddingBottom:'2%'}}>
                                                <div className='boderSeprator col-md-6'>
                                                    <p className='no-margin text-center'>FA Active</p>
                                                    <div className='d-flex justify-content-center'>
                                                        <p className='no-margin' style={{fontWeight:600}}>{filteredCountrydata[0].active_count}</p>
                                                        <p className='no-margin' style={{fontWeight:600,paddingLeft:'2%'}}>({!isNaN(((filteredCountrydata[0].active_count)/(filteredCountrydata[0].enable_count/100)).toFixed(1)) ? ((filteredCountrydata[0].active_count)/(filteredCountrydata[0].enable_count/100)).toFixed(1) : 0}%)</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='no-margin text-center'>FA Inactive</p>
                                                    <div className='d-flex justify-content-center'>
                                                        <p className='no-margin' style={{fontWeight:600}}>{(filteredCountrydata[0].enable_count-filteredCountrydata[0].active_count)}</p>
                                                        <p className='no-margin' style={{fontWeight:600,paddingLeft:'2%'}}>({!isNaN(((filteredCountrydata[0].enable_count-filteredCountrydata[0].active_count)/(filteredCountrydata[0].enable_count/100)).toFixed(1)) ? ((filteredCountrydata[0].enable_count-filteredCountrydata[0].active_count)/(filteredCountrydata[0].enable_count/100)).toFixed(1) : 0}%)</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>                                                                           
                                            {/* <div style={{position:'absolute',top:5,right:5}}>
                                                <RoundOffSplitter val={"These are customers who have had a disbursal made to them over the last 30 days (out of enabled customers)"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                            </div>  */}
                                        </div>
                                    </div>   
                                    { switch_data &&
                                    <div className='col-md-12'>
                                        <div className='manDash_roundBox'  style={{position:"relative",paddingTop:'5%',paddingBottom:'5%'}}>
                                            <div className='d-flex justify-content-center'>
                                                <p className='no-margin'>Switch Customers</p>
                                                <div className='d-flex justify-content-center' style={{paddingLeft:'5%'}}> 
                                                    <p className='no-margin' style={{fontWeight:600}}>{filteredCountrydata[0].cust_switch_count}</p>
                                                    <p className='no-margin' style={{fontWeight:600,paddingLeft:'2%'}}>({!isNaN((filteredCountrydata[0].cust_switch_count/(filteredCountrydata[0].enable_count/100)).toFixed(1)) ? (filteredCountrydata[0].cust_switch_count/(filteredCountrydata[0].enable_count/100)).toFixed(1) : 0}%)</p>
                                                </div>
                                            </div>
                                            {/* <div style={{position:'absolute',top:5,right:5}}>
                                                <RoundOffSplitter val={"Customers whose profiles are enabled on the system, meaning they can apply for loans (out of registered customers)"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                            </div>                                        */}
                                        </div>
                                    </div>    
                                    }
                                </div>
                            </div>
                                             
                            {/* <div className='manDash_content white_bg'>
                                <p className='no-margin manDash_header white_bg'>Impact</p>
                                <div className='d-flex justify-content-around' style={{marginTop:15}}>
                                    <div>
                                       <span>Female - {(filteredCountrydata[0].female_perc*100).toFixed(1)}%</span>
                                    </div>
                                    <div>
                                      <span>Youth - {(filteredCountrydata[0].youth_perc*100).toFixed(1)}%</span>
                                    </div>
                                    <div>
                                       <span>Male - {(filteredCountrydata[0].male_perc*100).toFixed(1)}%</span>
                                    </div>
                                </div>                            
                                <div className='row' style={{marginTop:15,marginBottom:15,marginLeft:0,marginRight:0}}>
                                    <div className='col-md-4'>                                        
                                        <RoundOffSplitter val={filteredCountrydata[0].supported_custs} unit={""} position={"left"}/>
                                    </div>
                                    <div className='col-md-8'>
                                        <span>Supported businesses</span>
                                    </div>
                                </div>
                                <div className='row' style={{marginTop:15,marginBottom:15,marginLeft:0,marginRight:0}}>
                                    <div className='col-md-4'>
                                        <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].tot_retail_txn_val :  (filteredCountrydata[0].tot_retail_txn_val*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} position={"left"}/>                                        
                                    </div>
                                    <div className='col-md-8'>
                                        <span>Amount of retail txns facilitated</span>
                                    </div>
                                </div>
                                <div className='row' style={{marginTop:15,marginBottom:15,marginLeft:0,marginRight:0}}>
                                    <div className='col-md-4'>                                        
                                        <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].cust_revenue :  (filteredCountrydata[0].cust_revenue*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} position={"left"}/>
                                    </div>
                                    <div className='col-md-8'>
                                        <span>Revenue generated monthly</span>
                                    </div>
                                </div>
                                <div className='row' style={{marginTop:15,marginBottom:15,marginLeft:0,marginRight:0}}>
                                    <div className='col-md-4'>                                        
                                        <RoundOffSplitter val={(filteredCountrydata[0].tot_retail_txn_count)} unit={""} position={"left"}/>
                                    </div>
                                    <div className='col-md-8'>
                                        <span>No. of retail txns facilitated</span>
                                    </div>
                                </div>
                                <div className='row' style={{marginTop:15,marginBottom:15,marginLeft:0,marginRight:0}}>
                                    <div className='col-md-4'>                                        
                                        <RoundOffSplitter val={(filteredCountrydata[0].people_benefited)} unit={""} position={"left"}/>
                                    </div>
                                    <div className='col-md-8'>
                                        <span>No. of retail users benifited</span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="cust_metrics">
                            <p className='no-margin manDash_header'>Customer Metrics</p>
                            <div className='row no-margin align-items-center d-flex justify-content-between p-3' style={{paddingBottom:15}}>
                                <div className='col-md-3'>
                                    <div className='manDash_roundBox'>
                                        <p className='no-margin text-center'>Registered  Customers</p>
                                        <p className='no-margin text-center' style={{fontWeight:600}}>{filteredCountrydata[0].reg_count}</p>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='manDash_roundBox'  style={{position:"relative"}}>
                                        <p className='no-margin text-center'>Enabled Customers</p>
                                        <div className='row no-margin'>
                                            <div className='boderSeprator col-md-6'>
                                                <p className='no-margin text-right' style={{fontWeight:600}}>{filteredCountrydata[0].enable_count}</p>
                                                <p className='no-margin text-right'>{(filteredCountrydata[0].enable_count/(filteredCountrydata[0].reg_count/100)).toFixed(1)}%</p>
                                            </div>
                                            <div className='col-md-6'>
                                                <p className='  no-margin text-gray' style={{fontWeight:600}}>{(filteredCountrydata[0].reg_count-filteredCountrydata[0].enable_count)}</p>
                                                <p className='no-margin text-gray'>{((filteredCountrydata[0].reg_count-filteredCountrydata[0].enable_count)/(filteredCountrydata[0].reg_count/100)).toFixed(1)}%</p>
                                            </div>
                                        </div>  
                                        <div style={{position:'absolute',top:5,right:5}}>
                                            <RoundOffSplitter val={"Customers whose profiles are enabled on the system, meaning they can apply for loans (out of registered customers)"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                        </div>                                       
                                    </div>
                                </div>
                                <div className='col-md-3'>                                    
                                    <div className='manDash_roundBox' style={{position:"relative"}}>                                        
                                        <p className='no-margin text-center'>Active Customers</p>                                        
                                        <div className='row no-margin'>
                                            <div className='boderSeprator col-md-6'>
                                                <p className='no-margin text-right' style={{fontWeight:600}}>{filteredCountrydata[0].active_count}</p>
                                                <p className='no-margin text-right'>{((filteredCountrydata[0].active_count)/(filteredCountrydata[0].enable_count/100)).toFixed(1)}%</p>
                                            </div>
                                            <div className='col-md-6'>                                                
                                                <p className='no-margin text-gray' style={{fontWeight:600}}>{(filteredCountrydata[0].enable_count-filteredCountrydata[0].active_count)}</p>
                                                <p className='no-margin text-gray'>{((filteredCountrydata[0].enable_count-filteredCountrydata[0].active_count)/(filteredCountrydata[0].enable_count/100)).toFixed(1)}%</p>
                                            </div>
                                        </div>
                                        <div style={{position:'absolute',top:5,right:5}}>
                                            <RoundOffSplitter val={"These are customers who have had a disbursal made to them over the last 30 days (out of enabled customers)"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                        </div> 
                                    </div>
                                </div>                                                            
                            </div>
                        </div> */}
                        <div className="cust_metrics">
                            <p className='no-margin manDash_header'>Portfolio Performance</p>
                            <p className='no-margin manDash_subHeader'>Outstanding Portfolio</p>
                            <div className='row mx-3 manDash_subContent'>
                                <div className='col-md-4 custMetricsbox_pad'>
                                    <p className='no-margin'>Principal</p>
                                    <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].principal_os :  (filteredCountrydata[0].principal_os*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>                                    
                                </div>
                                <div className='col-md-4 custMetricsbox_pad'>
                                    <p className='no-margin'>Fee</p>
                                    <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].fee_os :  (filteredCountrydata[0].fee_os*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>                                    
                                </div>
                                <div className='col-md-4 custMetricsbox_pad'>
                                    <p className='no-margin'>Outstanding Loans</p>
                                    <p className='no-margin' style={{fontWeight:600}}>{filteredCountrydata[0].os_count}</p>
                                </div>
                                <div className='col-md-4 custMetricsbox_pad'>
                                    <p className='no-margin'>Ontime Repayments</p>
                                    <p className='no-margin' style={{fontWeight:600}}>{(filteredCountrydata[0].ontime_repayment_rate*100).toFixed(1)}%</p>
                                </div>
                                {get('role_codes') !== 'investor' &&  <>
                                <div className='col-md-4 custMetricsbox_pad'>
                                    <p className='no-margin'>Overdue Amount</p>                                    
                                    <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].od_amount :  (filteredCountrydata[0].od_amount*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                                    <p className='no-margin'>{!isNaN((filteredCountrydata[0].od_amount/(filteredCountrydata[0].principal_os/100)).toFixed(1)) ? (filteredCountrydata[0].od_amount/(filteredCountrydata[0].principal_os/100)).toFixed(1) : 0}%</p>
                                </div>
                                <div className='col-md-4 custMetricsbox_pad'>
                                    <p className='no-margin'>Overdues</p>
                                    <p className='no-margin' style={{fontWeight:600}}>{filteredCountrydata[0].od_count}</p>
                                    <p className='no-margin'>{!isNaN((filteredCountrydata[0].od_count/(filteredCountrydata[0].os_count/100)).toFixed(1)) ? (filteredCountrydata[0].od_count/(filteredCountrydata[0].os_count/100)).toFixed(1) : 0}%</p>
                                </div>
                                </>
                            }
                            </div>
                            <p className='no-margin manDash_subHeader'>Portfolio at Risk</p>
                            <div className='row mx-3 manDash_subContent'>
                                <div className='col-md-3 custMetricsbox_pad'>
                                    <p className='no-margin'>PAR 15 - 30</p>
                                    <RoundOffSplitter val={!activeCurrency.currency_change ? (filteredCountrydata[0].par15 - filteredCountrydata[0].par30) :  ((filteredCountrydata[0].par15 - filteredCountrydata[0].par30)*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>                                    
                                    <p className='no-margin'>{!isNaN(((filteredCountrydata[0].par15-filteredCountrydata[0].par30)/(filteredCountrydata[0].principal_os/100)).toFixed(1)) ? ((filteredCountrydata[0].par15-filteredCountrydata[0].par30)/(filteredCountrydata[0].principal_os/100)).toFixed(1) :0} % of OS Principal</p>
                                </div>

                                    <div className='col-md-3 custMetricsbox_pad'>
                                    <p className='no-margin'>PAR 30 - 60</p>
                                    <RoundOffSplitter val={!activeCurrency.currency_change ? (filteredCountrydata[0].par30 - filteredCountrydata[0].par60) :  ((filteredCountrydata[0].par30 - filteredCountrydata[0].par60)*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>                                    
                                    <p className='no-margin'>{!isNaN(((filteredCountrydata[0].par30 - filteredCountrydata[0].par60)/(filteredCountrydata[0].principal_os/100)).toFixed(1)) ? ((filteredCountrydata[0].par30 - filteredCountrydata[0].par60)/(filteredCountrydata[0].principal_os/100)).toFixed(1) : 0} % of OS Principal</p>
                                    
                                </div>
                                
                                <div className='col-md-3 custMetricsbox_pad'>
                                    <p className='no-margin'>PAR 60 - 90</p>
                                    <RoundOffSplitter val={!activeCurrency.currency_change ?(filteredCountrydata[0].par60 - filteredCountrydata[0].par90) :  ((filteredCountrydata[0].par60 - filteredCountrydata[0].par90)*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>                                    
                                    <p className='no-margin'>{!isNaN(((filteredCountrydata[0].par60 - filteredCountrydata[0].par90)/(filteredCountrydata[0].principal_os/100)).toFixed(1)) ? ((filteredCountrydata[0].par60 - filteredCountrydata[0].par90)/(filteredCountrydata[0].principal_os/100)).toFixed(1) : 0} % of OS Principal</p>
                                </div>
                                
                                
                                <div className='col-md-3 custMetricsbox_pad'>
                                    <p className='no-margin'>PAR 90+</p>
                                    <RoundOffSplitter val={!activeCurrency.currency_change ?(filteredCountrydata[0].par90) :  ((filteredCountrydata[0].par90)*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>                                    
                                    <p className='no-margin'>{!isNaN(((filteredCountrydata[0].par90)/(filteredCountrydata[0].principal_os/100)).toFixed(1)) ? ((filteredCountrydata[0].par90)/(filteredCountrydata[0].principal_os/100)).toFixed(1) : 0} % of OS Principal</p>
                                </div>
                                
                
                            </div>
                        </div>
                        {<div className="cust_metrics">
                            <p className='no-margin manDash_header'>Best Performance</p>
                            <div className='row bestPer_box'>
                                <div className='col-md-6 px-5 custMetricsbox_pad'>
                                    <div className='bestPer_contentbox'>
                                        <p>Maximum Outstanding</p>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <RoundOffSplitter val={filteredCountrydata[0].max_os !== null && !isNaN(filteredCountrydata[0].max_os.split(',')[0]) ? (!activeCurrency.currency_change ? Number(filteredCountrydata[0].max_os !== null && filteredCountrydata[0].max_os.split(',')[0]) :  (Number(filteredCountrydata[0].max_os !== null && filteredCountrydata[0].max_os.split(',')[0]) * activeCurrency.active_currency_val)): 0} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                                            {filteredCountrydata[0].max_os !== null && filteredCountrydata[0].max_os.split(',')[1] &&
                                                <p className='no-margin'>{format_date_tbl(filteredCountrydata[0].max_os.split(',')[1],false)}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 px-5 custMetricsbox_pad'>
                                    <div className='bestPer_contentbox'>
                                        <p>Maximum Ontime Repayment</p>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <p className='no-margin' style={{fontWeight:600}}>{filteredCountrydata[0].max_ontime_repay_rate !== null ? (filteredCountrydata[0].max_ontime_repay_rate.split(',')[0] != 'None'  ? (Number( filteredCountrydata[0].max_ontime_repay_rate.split(',')[0] * 100)).toFixed(1) : 0) : 0 }%</p>
                                            {filteredCountrydata[0].max_ontime_repay_rate !== null && filteredCountrydata[0].max_ontime_repay_rate.split(',')[1] != 'None' && 
                                                <p className='no-margin'>{format_date_tbl(filteredCountrydata[0].max_ontime_repay_rate.split(',')[1],false)}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/*<div className='col-md-6 custMetricsbox_pad'>*/}
                                {/*    <div className='bestPer_contentbox'>*/}
                                {/*        <p>Maximum Monthly Disburshment</p>*/}
                                {/*        <div className='d-flex align-items-center justify-content-between'>*/}
                                {/*            <p className='no-margin' style={{fontWeight:600}}>20.7 M UGX</p>*/}
                                {/*            <p className='no-margin'>Jan 2022</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className='col-md-6 custMetricsbox_pad'>*/}
                                {/*    <div className='bestPer_contentbox'>*/}
                                {/*        <p>Maximum Monthly Revenue</p>*/}
                                {/*        <div className='d-flex align-items-center justify-content-between'>*/}
                                {/*            <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].revenue :  (filteredCountrydata[0].revenue*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false}/>*/}
                                {/*            <p className='no-margin'>Jan 2022</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className='col-md-6 custMetricsbox_pad'>*/}
                                {/*    <div className='bestPer_contentbox'>*/}
                                {/*        <p>Maximum Customers Acquired</p>*/}
                                {/*        <div className='d-flex align-items-center justify-content-between'>*/}
                                {/*            <p className='no-margin' style={{fontWeight:600}}>79</p>*/}
                                {/*            <p className='no-margin'>Dec 2021</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>}

                        {<div className="cust_metrics">
                            <p className='no-margin manDash_header'>Revenue</p>
                            <div className='row bestPer_box'>

                            <div className='col-md-6 px-5 custMetricsbox_pad'>
                                    <div className='bestPer_contentbox'>
                                        <p>FA Revenue Per Customer ({filteredCountrydata[0].cust_count_for_rev_calc} Customers )</p>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].rev_per_cust :  (filteredCountrydata[0].rev_per_cust*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={true} position={"top"}/>
                                        
                                            <div style={{fontSize:'20px'}}>
                                           <RoundOffSplitter val={ `Average FA revenue per customer for   ${moment(filteredCountrydata[0].report_date).subtract(3, 'months').format('MMM YYYY') } - ${moment(filteredCountrydata[0].report_date).format("MMM YYYY") } `} unit={""} align_center={true} position={"top"} notify={true}/>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 px-5 custMetricsbox_pad'>
                                    <div className='bestPer_contentbox'>
                                        <p>FA Revenue Per RM ({filteredCountrydata[0].rm_count_for_rev_calc} RMs )</p>
                                        <div className='d-flex align-items-center justify-content-between'>
                                          <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].rev_per_rm :  (filteredCountrydata[0].rev_per_rm*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={true} position={"top"}/>
                                           <div style={{fontSize:'20px'}}>
                                           <RoundOffSplitter val={ `Average FA revenue per RM for  ${ moment(filteredCountrydata[0].report_date).subtract(3, 'months').format('MMM YYYY') } - ${moment(filteredCountrydata[0].report_date).format("MMM YYYY") } `} unit={""} align_center={true} position={"top"} notify={true}/>    
                                            </div>
                                            {/* <p className='no-margin'>{format_date_tbl(filteredCountrydata[0].max_os.split(',')[1],false)}</p> */}
                                        </div>
                                    </div>
                                </div>
                                { switch_data &&
                                <div className='col-md-6 px-5 custMetricsbox_pad'>
                                    <div className='bestPer_contentbox'>
                                        <p>Switch Revenue Per Customer ({filteredCountrydata[0].last_3months_sw_cust_count} Customers )</p>
                                        <div className='d-flex align-items-center justify-content-between'>
                                          <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].last_3month_sw_rev_per_cust :  (filteredCountrydata[0].last_3month_sw_rev_per_cust*activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={true} position={"top"}/>
                                           <div style={{fontSize:'20px'}}>
                                           <RoundOffSplitter val={ `Average switch revenue per customer for   ${ moment(filteredCountrydata[0].report_date).subtract(3, 'months').format('MMM YYYY') } - ${moment(filteredCountrydata[0].report_date).format("MMM YYYY") } `} unit={""} align_center={true} position={"top"} notify={true}/>    
                                            </div>
                                            {/* <p className='no-margin'>{format_date_tbl(filteredCountrydata[0].max_os.split(',')[1],false)}</p> */}
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>}
                    </div>
                </div>                    
            </div>    
        )
    } 
    else{
        return(
            <>
            {loader ? 
            <div style={{marginTop:'15%'}}>
             <p className='text-light mt-5 text-center' >Loading...</p>
             <div className="product_takeup_loader "></div>
             </div>
            :
            
            <div className='text-center text-white '  style={{marginTop:'15%'}}>No data</div>
            }</>
        )
    }  
}
