export function lbl_flow_fee_duration(flow_fee_duration, duration ){
	if(is_per_day_loan(flow_fee_duration)){
		 return  "Each day" ;
	}
	else{
		return duration  + " days" ;
	}
}

export function is_per_day_loan(flow_fee_duration){
	if(flow_fee_duration === "each_day" || flow_fee_duration === "1"){
		return true;
	}
	else{
		 return false;
	}
}

export function lbl_amt(amount, currency_code = null){
	var nf = new Intl.NumberFormat();
	if(currency_code){
		return nf.format(amount) + " " + currency_code;	
	}
	return nf.format(amount)
}

export function lbl_dur(duration, flow_fee_duration = null, loan_purpose = null){
	var suffix = '';
	if(is_per_day_loan(flow_fee_duration)){
		suffix = " (max duration)";
	}
	if(duration == "1" && (loan_purpose == "float_advance" || loan_purpose == null)){
		return duration + " day" + suffix;
	}else if(duration > "1" && (loan_purpose == "float_advance"|| loan_purpose == null)){
		return duration + " days" + suffix;
	}else if(duration == "1" && loan_purpose == "terminal_financing"){
		return duration + " month" + suffix;
	}else if(duration > "1" && loan_purpose == "terminal_financing"){
		return duration + " months" + suffix;
	}
}

export function lbl_flow_fee_obj(prod, currency_code){
		const calc_fee1 = lbl_amt(calc_fee_from_percent(prod), currency_code);
		var value = prod.flow_fee_type === "Percent" ? prod.flow_fee+"% | " :  "(FLAT) " + lbl_amt(prod.flow_fee, currency_code) 
		var suffix = '';
		
		if(prod.flow_fee_type === "Percent"){
			suffix += calc_fee1;
		}

		if(is_per_day_loan(prod.flow_fee_duration)){
			suffix += " / day";
			
		}
	return value + suffix;
}

export function calc_fee(product){
	
	let flow_fee = product.flow_fee;
	if(product.flow_fee_type === "Percent"){
		flow_fee = calc_fee_from_percent(product);
	}
	if(is_per_day_loan(product.flow_fee_duration)){
		flow_fee = flow_fee * product.duration;
	}
	return Math.round(flow_fee);
}

export function calc_fee_from_percent(product){
	return loan_amt(product) * (product.flow_fee / 100);
}

export function lbl_repayment_amt(product, currency_code = null){

	let suffix = '';
	if(is_per_day_loan(product.flow_fee_duration)){
		suffix = " *"
	}

	return lbl_amt(repayment_amt(product), currency_code)  + suffix; 
}	

export function repayment_amt(product){
	let flow_fee = calc_fee(product)

	return loan_amt(product) + flow_fee 
}

export function loan_amt(product){
	if(product.hasOwnProperty('max_loan_amount')){
		return product.max_loan_amount;
	}else if (product.hasOwnProperty('loan_principal')){
		return product.loan_principal;
	}
}	

export function formatNumber(num) {
	if (Math.abs(num) >= 1.0e9) {
	  return (num / 1.0e9).toFixed(1) + " B"; 
	} else if (Math.abs(num) >= 1.0e6) {
	  return (num / 1.0e6).toFixed(1) + " M"; 
	} else if (Math.abs(num) >= 1.0e3) {
	  return (num / 1.0e3).toFixed(1) + " K"; 
	} else {
	  return num; 
	}
  }


